import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { UpdatePropertyApiCall } from "../../apiCalls/AdminProperty/UpdatePropertyApiCall";
import Loader from "../../components/Loader/Loader.component";
import {
  updateProperty
} from "../../redux/adminProperty/adminProperty.selector";
import useWindowDimensions from "../../utils/CustomHooks/useWindowDimension";
import "../AdminPage/AdminPage.styles.scss";

import CancelIcon from "@material-ui/icons/Cancel";
import { PropertySearchListApiCall } from "../../apiCalls/Search/PropertySearchListApiCall";
import { FileRemoveUserApiCall } from "../../apiCalls/UserProperty/FileRemoveUserApiCall";
import { FileUploadUserApiCall } from "../../apiCalls/UserProperty/FileUploadUserApiCall";
import "../../components/Modals/Modals.styles.scss";
import { getPropertySearchList } from "../../redux/search/search.selector";
import NoDataIMG from "../../assets/img/no-data2.jpg";

const UploadPropertyImages = ({ propertyUniqueId }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const updatePropertyStatus = useSelector(updateProperty);
  const { width } = useWindowDimensions();
 
  const propertyAllData = useSelector(getPropertySearchList);

  useEffect(() => {
    dispatch(
      PropertySearchListApiCall(
        1,
        "",
        "",
        propertyUniqueId,
        "",
        "",
        0,
        150000000,
        0,
        10000,
        ""
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(updatePropertyStatus?.data != null) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      
    }
  }, [updatePropertyStatus.data]);

  //Toast View
  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  };

  //Add Property
  const handleAddProperty = (fileInfos) => {
    let data = {
      image: fileInfos,
      property_id: propertyAllData?.data?.data[0]?.property_id,
      upload_by_type: "user",
    };
    dispatch(UpdatePropertyApiCall(data, toastView, "user"));
  
  };
  
  const [files, setFiles] = useState([]);

  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [fileInfos, setFileInfos] = useState([]);

  const [uploadFile, setuploadFile] = useState(-1);
  const [disableSave, setDisableSave] = useState(false);
  const imageInput = useRef(null);

  const selectFile = (event) => {
    if (event.target.files.length + fileInfos?.length > 15) {
      toastView("Maximum number of images allowed is 15", "warning");
    } else {
      if (event.target.files[0]?.size > 10000000) {
        toastView("Maximum size allowed is 10MB", "warning");
      } else {
        setFiles(event.target.files);
      }
    }
  };
  useEffect(() => {
    if (uploadFile !== -1) {
      setDisableSave(true);
      let currentItem = files[uploadFile];
      setProgress(0);
      setCurrentFile(files[uploadFile]);

      FileUploadUserApiCall(
        currentItem,
        (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        },
        "user"
      )
        .then((response) => {
          setFileInfos([...fileInfos, response.data?.data?.file_id]);

          if (uploadFile === files.length - 1) {
            setuploadFile(-1);
            setCurrentFile(undefined);
            setFiles([]);
          } else {
            setuploadFile((old) => old + 1);
          }
        })
        .catch((error) => {
          setDisableSave(false);
          setProgress(0);
          setCurrentFile(undefined);
        });
    } else {
      setDisableSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);

  const upload = () => {
    if (files?.length + fileInfos?.length > 15) {
      toastView("Maximum number of images allowed is 15", "warning");
    } else {
      if (files.length === 0) {
        toastView("No File Chosen", "warning");
      } else {
        setuploadFile(0);
      }
    }
  };

  useEffect(() => {
    setFileInfos(propertyAllData?.data?.data[0]?.images ?? []);
  }, [propertyAllData?.data?.data[0]?.images]);
 
  const removeImage = (fileId) => {
    let temp = [];
    for (let i = 0; i < fileInfos?.length; i++) {
      if (fileInfos[i] !== fileId) {
        temp.push(fileInfos[i]);
      }
    }
    setFileInfos(temp);
    imageInput.current.value = "";
    dispatch(FileRemoveUserApiCall(fileId, toastView, "user"));
  };
  const handleSubmit = (event) => {
    handleAddProperty(fileInfos);
  };


  return (
    <div>
      <Loader visible={propertyAllData.loader || updatePropertyStatus.loader} />
      {
        propertyAllData.loader && (
          <div style={{
            minHeight: "50vh"}}></div>
        )
      }
      {
        propertyAllData?.data?.total_page_count === 0 && (
          <div
            className="col"
            style={{
              minHeight: "50vh",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexDirection: "column", // Ensure vertical stacking of children
              textAlign: "center"
            }}
          >
            <img src={NoDataIMG} alt=" " />
            <h1>
              No such property found. Please use a valid link to upload
              images.
            </h1>
          </div>
        )
      }
      <div className="ml-5 mr-5 mt-5 mb-5">
        <div className="row justify-content-center">
        {propertyAllData?.data?.data[0]?.title && (
            <div className="mr-5">
              <p className="property-details-text">
                <b style={{fontSize: "20px"}}>Borrower Name</b>
                <span style={{fontSize: "20px"}}> : {propertyAllData?.data?.data[0].title}</span>
              </p>
            </div>
          )}
          {propertyAllData?.data?.data[0]?.property_uniqueid && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>Property Id</b>
                <span>
                  {" "}
                  : {propertyAllData?.data?.data[0]?.property_uniqueid}
                </span>
              </p>
            </div>
          )}
          {propertyAllData?.data?.data[0]?.category_name && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>Category</b>
                <span> : {propertyAllData?.data?.data[0]?.category_name}</span>
              </p>
            </div>
          )}
          {propertyAllData?.data?.data[0]?.state_name && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>State Name</b>
                <span> : {propertyAllData?.data?.data[0].state_name}</span>
              </p>
            </div>
          )}
               
        </div>
        <div className="row justify-content-center">
        {propertyAllData?.data?.data[0]?.city_name && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>City Name</b>
                <span> : {propertyAllData?.data?.data[0].city_name}</span>
              </p>
            </div>
          )}
          {propertyAllData?.data?.data[0]?.address && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>Property Address</b>
                <span> : {propertyAllData?.data?.data[0].address}</span>
              </p>
            </div>
          )} 
        {propertyAllData?.data?.data[0]?.property_area && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>Area</b>
                <span>
                  {" "}
                  : {propertyAllData?.data?.data[0]?.property_area}{" "}
                  {propertyAllData?.data?.data[0]?.property_area_unit}
                </span>
              </p>
            </div>
          )}
          {propertyAllData?.data?.data[0]?.price && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>Reserve Price</b>
                <span> : {propertyAllData?.data?.data[0].price}</span>
              </p>
            </div>
          )}
        {/* {propertyAllData?.data?.data[0]?.type_name && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>Property Type</b>
                <span> : {propertyAllData?.data?.data[0].type_name}</span>
              </p>
            </div>
          )}
          
          {propertyAllData?.data?.data[0]?.locality_name && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>Locality Name</b>
                <span> : {propertyAllData?.data?.data[0].locality_name}</span>
              </p>
            </div>
          )}
          
          {propertyAllData?.data?.data[0]?.other_details && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>Other Details</b>
                <span> : {propertyAllData?.data?.data[0].other_details}</span>
              </p>
            </div>
          )}
          {propertyAllData?.data?.data[0]?.property_condition && (
            <div className="mr-5">
              <p className="property-details-text">
                <b>Property Condition</b>
                <span>
                  {" "}
                  : {propertyAllData?.data?.data[0].property_condition}
                </span>
              </p>
            </div>
          )} */}
        </div>
        
        {propertyAllData?.data?.data[0]?.images && (
          <>
            <Form.Label className="form-label">
              Images{"  "}
              <span style={{ fontSize: 12, color: "red" }}>
                (jpg / jpeg / png), Max size: 10MB, Max No: 15
              </span>
            </Form.Label>
            <div className="mb-3 d-flex flex-column upload-image">
              <div className="d-flex flex-row file-btn-wrap">
                <div className="d-flex flex-column">
                  {currentFile && (
                    <div
                      className="progress"
                      style={{ width: width > 576 ? "200px" : "100px" }}
                    >
                      <div
                        className="progress-bar progress-bar-info progress-bar-striped"
                        role="progressbar"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: progress + "%" }}
                      >
                        {progress}%
                      </div>
                    </div>
                  )}

                  <label className="btn btn-default">
                    <input
                      type="file"
                      className="file-input-image"
                      accept="image/*"
                      multiple
                      onChange={selectFile}
                      ref={imageInput}
                    />
                  </label>
                </div>

                <div className="d-flex flex-row align-items-center">
                  <button
                    className="btn btn-success"
                    disabled={!files}
                    onClick={upload}
                    type="button"
                  >
                    Upload
                  </button>
                </div>
              </div>
              <div className="card">
                <ul className="list-group list-group-flush d-flex flex-row images-list">
                  {fileInfos &&
                    fileInfos.map((file, index) => (
                      <li className="list-group-item" key={index}>
                        <CancelIcon
                          style={{
                            width: 20,
                            height: 20,
                            position: "absolute",
                            fill: "red",
                            top: 3,
                            right: 13,
                          }}
                          onClick={() => removeImage(file)}
                        />
                        <img
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}${file}`}
                          width="50px"
                          height="50px"
                        />
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </>
        )}
        {propertyAllData.data && propertyAllData?.data?.total_page_count !== 0 && (
          <Button
            variant="dark"
            onClick={handleSubmit}
            type="submit"
            disabled={disableSave}
          >
            Save
          </Button>
        )}
      </div>
      {/* </Form> */}
    </div>
  );
};

export default UploadPropertyImages;