import axios from "axios";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { store } from "../../redux/store";

export function FileUploadUserApiCall(file, onUploadProgress, type) {
  const state = store.getState();
  const authToken = state.token.userToken;
  let formData = new FormData();

  let url="";
  if(type === "user") {
    url = URL_CONSTANTS.UPLOAD_FILE_BY_USER;
  } else {
    url = URL_CONSTANTS.UPLOAD_FILE;
  }
  formData.append("file", file);
  return axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${authToken}`,
      "Access-Control-Allow-Origin": "*",
      "Accept": "application/json"
    },
    onUploadProgress
  });
}