import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Button from "react-bootstrap/Button";
import Loader from "../../../components/Loader/Loader.component";
import { InterestedReportApiCall } from "../../../apiCalls/Report/InterestedReportApiCall";
import { SignedUpReportApiCall } from "../../../apiCalls/Report/SignedUpReportApiCall";
import { UploadedReportApiCall } from "../../../apiCalls/Report/UploadedReportApiCall";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  getInterestedReport,
  getSignedUpReport,
  getUploadedReport,
} from "../../../redux/report/report.selector";
import { adminLoginState } from "../../../redux/persistantData/persistantData.selector";
import {
  interestedReport,
  signedUpReport,
  uploadedReport,
} from "../../../redux/report/report.actions";
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./UserConfig.styles.scss";
import { TextField, Typography } from "@material-ui/core";
import { Box, Grid } from "@mui/material";

const columns = [
  { field: "id", headerName: "ID", width: 20 },
  { field: "UserName", headerName: "UserName", width: 140 },
  { field: "UserEmail", headerName: "UserEmail", width: 150 },
  { field: "UserPhone", headerName: "UserPhone", width: 150 },
  { field: "City", headerName: "City", width: 150 },
  { field: "PropertyType", headerName: "PropertyType", width: 180 },
  { field: "UserType", headerName: "UserType", width: 150 },
  { field: "Budget", headerName: "Budget", width: 150 },
  {
    field: "AccountCreatedDate",
    headerName: "AccountCreatedDate",
    width: 230,
    type: Date(),
  },

  // {
  //   field: 'age',
  //   headerName: 'Age',
  //   type: 'number',
  //   width: 90,
  // },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
];

const data = [
  {
    id: 1,
    name: "Rahul",
    email: "rahul@gmail.com",
    phoneNo: 9552391395,
    city: "Mumbai",
    propertyType: "Residential",
    type: "Buyer",
    Date: "2022-11-13 09:13:28",
    budget: "other",
  },
  {
    id: 2,
    name: "Amit",
    email: "amit@gmail.com",
    phoneNo: 9552391396,
    city: "Delhi",
    propertyType: "Commercial",
    type: "Investor",
    Date: "2022-10-25 11:45:19",
    budget: "40-80 Lakhs",
  },
  {
    id: 3,
    name: "Sara",
    email: "sara@yahoo.com",
    phoneNo: 9552391397,
    city: "Bangalore",
    propertyType: "Residential",
    type: "Buyer",
    Date: "2022-12-05 14:27:36",
    budget: "40-80 Lakhs",
  },
  {
    id: 4,
    name: "Priya",
    email: "priya@gmail.com",
    phoneNo: 9552391398,
    city: "Chennai",
    propertyType: "Residential",
    type: "Buyer",
    Date: "2022-11-18 10:09:45",
    budget: "10-30 Lakhs",
  },
  {
    id: 5,
    name: "Rajesh",
    email: "rajesh@hotmail.com",
    phoneNo: 9552391399,
    city: "Hyderabad",
    propertyType: "Commercial",
    type: "Investor",
    Date: "2022-10-31 12:55:22",
    budget: "40-80 Lakhs",
  },
  {
    id: 6,
    name: "Anita",
    email: "anita@gmail.com",
    phoneNo: 9552391400,
    city: "Mumbai",
    propertyType: "Residential",
    type: "Buyer",
    Date: "2022-12-02 09:37:14",
    budget: "40-60 Lakhs",
  },
  {
    id: 7,
    name: "Kumar",
    email: "kumar@yahoo.com",
    phoneNo: 9552391401,
    city: "Kolkata",
    propertyType: "Residential",
    type: "Investor",
    Date: "2022-10-20 08:18:31",
    budget: "10-25 Lakhs",
  },
  {
    id: 8,
    name: "Neha",
    email: "neha@gmail.com",
    phoneNo: 9552391402,
    city: "Pune",
    propertyType: "Commercial",
    type: "Buyer",
    Date: "2022-11-29 13:45:50",
    budget: "40-80 Lakhs",
  },
  {
    id: 9,
    name: "Sandeep",
    email: "sandeep@hotmail.com",
    phoneNo: 9552391403,
    city: "Chennai",
    propertyType: "Residential",
    type: "Investor",
    Date: "2022-12-08 10:33:09",
    budget: "Above 80 Lakhs",
  },
  {
    id: 10,
    name: "Pooja",
    email: "pooja@gmail.com",
    phoneNo: 9552391404,
    city: "Delhi",
    propertyType: "Commercial",
    type: "Buyer",
    Date: "2022-10-22 11:20:07",
    budget: "Above 40 Lakhs",
  },
];

const UserReport = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const interestedReportState = useSelector(getInterestedReport);
  const uploadedReportState = useSelector(getUploadedReport);
  const signedUpReportState = useSelector(getSignedUpReport);
  const adminLogin = useSelector(adminLoginState);
  const [dataArray, setDataArray] = useState([]);

  const [filterArray, setFilterArray] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);

  useEffect(() => {
    if (interestedReportState.data !== null) {
      let anchor = document.createElement("a");
      anchor.href = `data:text/csv;charset=utf-8,${escape(
        interestedReportState.data
      )}`;
      anchor.download = "interestedReport.csv";
      anchor.click();
      dispatch(interestedReport(null, false));
    }
  }, [interestedReportState.data]);

  useEffect(() => {
    if (signedUpReportState.data !== null) {
      let anchor = document.createElement("a");
      anchor.href = `data:text/csv;charset=utf-8,${escape(
        signedUpReportState.data
      )}`;
      // anchor.download = "signedUpReport.csv";
      // anchor.click();

      // console.log(signedUpReportState.data);

      if (!signedUpReportState || !signedUpReportState.data) {
        console.error(
          "Invalid data in signedUpReportState:",
          signedUpReportState
        );
        return;
      }

      const [headers, ...rows] = signedUpReportState.data
        .split("\n")
        .map((row) => row.split(","));

      const formattedData = rows.map((row, rowIndex) => {
        const obj = { id: rowIndex + 1 }; // Adding "id" property based on row index
        headers.forEach((header, index) => {
          // console.log("Processing header:", header);
          // console.log("Processing row:", row[index]);

          const cleanHeader = header.replace(/"/g, "");
          const cleanValue = (row[index] || "").replace(/"/g, "");
          obj[cleanHeader] = cleanValue;
        });
        return obj;
      });

      setDataArray(formattedData);
      // console.log("Data", formattedData);

      dispatch(signedUpReport(null, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedUpReportState.data]);

  useEffect(() => {
    if (uploadedReportState.data !== null) {
      let anchor = document.createElement("a");
      anchor.href = `data:text/csv;charset=utf-8,${escape(
        uploadedReportState.data
      )}`;
      anchor.download = "uploadedReport.csv";
      anchor.click();
      dispatch(uploadedReport(null, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedReportState.data]);

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance });
  };

  const handleReport = (type) => {
    if (type === "INTERESTED") {
      dispatch(InterestedReportApiCall(toastView));
    } else if (type === "SIGNEDUP") {
      dispatch(SignedUpReportApiCall(toastView));
    } else if (type === "UPLOADED") {
      dispatch(UploadedReportApiCall(toastView));
    }
  };

  useEffect(() => {
    handleReport("SIGNEDUP");
  }, []);

  function filterByDate() {
    const filteredUsers = dataArray.filter((user) => {
      const userDate = new Date(user.AccountCreatedDate);
      return userDate >= new Date(fromDate) && userDate <= new Date(toDate);
    });

    setFilterArray(filteredUsers);
  }

  const downloadListHandler = () => {
    alert("asd");
    const wb = XLSX.utils.book_new();
    const userlist = XLSX.utils.json_to_sheet(
      filterArray.length > 0 ? filterArray : dataArray
    );
    XLSX.utils.book_append_sheet(wb, userlist, "User Report");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });
    saveAs(blob, "User Report.xlsx");

  };

  // Example usage: filtering from '2022-12-01' to '2023-04-01'

  return (
    <div
      className="p-5 bg-white w-100 "
      style={{ borderRadius: "16px", overflow: "hidden" }}
    >
      <Loader
        visible={
          interestedReportState.loader ||
          signedUpReportState.loader ||
          uploadedReportState.loader
        }
      />

      <h3>User Report</h3>

      <Box
        display={"flex"}
        gap={"10px"}
        alignItems={"center"}
        height={"100%"}
        width={"100%"}
        justifyContent={"space-between"}
        py={3}
      >
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
        >
          <Grid item md={6} sm={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item>
                <TextField
                  id="From Date"
                  label="From Date"
                  variant="outlined"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="To Date"
                  label="To Date"
                  variant="outlined"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                height={"100%"}
                justifyContent={"center"}
              >
                <Button onClick={filterByDate}>Filter</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box textAlign={"end"} pb={1}>
        <Button onClick={downloadListHandler}>Download List</Button>
      </Box>
      <div style={{ height: "100%", width: "1124px" }}>
        {filterArray.length > 0 ? (
          <DataGrid
            rows={filterArray}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 20, 50]}
            // checkboxSelection
            sx={{
              ".MuiDataGrid-root": {
                overflow: "scroll",
              },
            }}
          />
        ) : (
          <DataGrid
            rows={dataArray}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 20, 50]}
            // checkboxSelection
            sx={{
              ".MuiDataGrid-root": {
                overflow: "scroll",
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UserReport;
