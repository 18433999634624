import { getRequestHeaderUser } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { fileRemoveUser } from "../../redux/userProperty/userProperty.actions";

export function FileRemoveUserApiCall(fileId, toastView, type) {
  return (dispatch) => {
    dispatch(fileRemoveUser(null, true));

    let url="";
    if(type === "user") {
      url =`${URL_CONSTANTS.REMOVE_FILE_BY_USER}${fileId}`;
    } else {
      url = `${URL_CONSTANTS.FILE_REMOVE_USER}${fileId}`;
    }

    getRequestHeaderUser(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(fileRemoveUser(res.data.data, false));
          toastView("File Removed Successfully", 'success');
        }
      })
      .catch((error) => {
        dispatch(fileRemoveUser(null, false));
      });
  };
}