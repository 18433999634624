import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { getAdminRoles } from "../../redux/admin/admin.actions";

export function GetAdminRolesApiCall(user_type) {
  return (dispatch) => {
    dispatch(getAdminRoles(null, true));
    let url = "";
    if(user_type === "user") {
      url = URL_CONSTANTS.GET_ALL_ADMIN_ROLES;
    } else {
      url = URL_CONSTANTS.GET_ADMIN_ROLES;
    }

    getRequestHeader(url)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          dispatch(getAdminRoles(res?.data?.data, false));
        }
      })
      .catch((error) => {
        dispatch(getAdminRoles(null, false));
      });
  };
}