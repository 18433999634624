import { useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Navbar from "./components/Navbar/Navbar.component";
import HomePage from "./pages/HomePage/HomePage.component";
import AdminPage from "./pages/AdminPage/AdminPage.component";
import AboutUs from "./pages/AboutUs/AboutUs.component";
import BankAuction from "./pages/BankAuction/BankAuction.component";
import BuySale from "./pages/Buy-sale/BuySale.component";
import Footer from "./components/Footer/Footer.component";
import Login from "./pages/Auth/Login/Login.component";
import Signup from "./pages/Auth/Signup/Signup.component";
import AdminLogin from "./pages/AdminLogin/AdminLogin.component";
import Disclaimer from "./pages/Shared/Disclaimer.component";
import AuctionPropertyDetails from "./pages/AuctionPropertyDetails/AuctionPropertyDetails.component";
import BuySalePropertyDetails from "./pages/BuySalePropertyDetails/BuySalePropertyDetails.component";
import UploadPropertyImages from "./pages/UploadPropertyImages/UploadPropertyImages.component";
import SearchResults from "./pages/SearchResults/SearchResults.component";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.component";
import OurServices from "./pages/OurServices/OurServices.component";
import ContactUs from "./pages/ContactUs/ContactUs.component";
import MyProfile from "./pages/MyProfile/MyProfile.component";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage.component";
import KnowledgeHub from "./pages/KnowledgeHub/KnowledgeHub.component";
import Downloads from "./pages/Downloads/Downloads.component";
import VastuConsultants from "./pages/VastuConsultants/VastuConsultants.component";
import PropertyLawyers from "./pages/PropertyLawyers/PropertyLawyers.component";
import PropertyDealers from "./pages/PropertyDealers/PropertyDealers.component";
import FAQ from "./pages/FAQ/FAQ.component";
import AdvertiseWithUs from "./pages/AdvertiseWithUs/AdvertiseWithUs.component";
import BusinessAssociates from "./pages/BusinessAssociates/BusinessAssociates.component";
import BusinessEnquiries from "./pages/BusinessEnquiries/BusinessEnquiries.component";
import Architects from "./pages/Architects/Architects.component";
import PropertyValuers from "./pages/PropertyValuers/PropertyValuers.component";
import EditProfile from "./pages/EditProfile/EditProfile.page";
import { getAdminToken, getUserToken } from "./redux/token/token.selector";
import { getSavePropertyLink } from "./redux/user/user.selector";
import { toast } from "./redux/file/file.selector";
import "./App.css";
import NoDataIMG from "./assets/img/no-data2.jpg";

export const SearchContext = React.createContext({
  searchData: {
    type: "",
    areatype: "",
    subType: "",
    city: "",
    locality: "",
    bedsSelected: "",
    priceSelected: "",
    propertyId: "",
    minAreaSelected: "",
    maxAreaSelected: "",
    cityData: {},
    localityData: {},
    price: [0, 1500],
    area: [300, 10000],
    bankInsitute: {},
  },
  setSearchData: (data) => {},
  SearchPageNumber: "",
  setSearchPageNumber: (data) => {},
});

function App() {
  let location = useLocation();
  const userToken = useSelector(getUserToken);
  const adminToken = useSelector(getAdminToken);
  const savePropertyLink = useSelector(getSavePropertyLink);
  const [defaultData, setDefaultData] = useState([]);
  const [SearchPageNumber, setSearchPageNumber] = useState(1);
  const toastState = useSelector(toast);
  const { addToast } = useToasts();
  const mountedToast = useRef();

  useEffect(() => {
    if (!mountedToast.current) {
      mountedToast.current = true;
    } else if (toastState?.message?.length > 0) {
      addToast(toastState?.message, {
        appearance: toastState?.appearance,
        autoDismiss: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastState.message]);

  const setData = (data) => {
    setDefaultData(data);
  };

  const setPageNumber = (data) => {
    setSearchPageNumber(data);
  };

  console.warn = () => {};

  console.error = () => {};

  // console.log = () => {}

  return (
    <SearchContext.Provider
      value={{
        searchData: defaultData,
        setSearchData: setData,
        SearchPageNumber: SearchPageNumber,
        setSearchPageNumber: setPageNumber,
      }}
    >
      <div className="App">
        {!location.pathname?.includes("/admin") && <Navbar />}
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            path="/admin"
            render={() =>
              adminToken ? <AdminPage /> : <Redirect to="/admin/login" />
            }
          />
          <Route
            exact
            path="/property/uploadImages/:dynamicPart"
            render={({ match }) => {
              const dynamicPart = match.params.dynamicPart;
              const parts = dynamicPart.split("-");
              const property_unique_id = parts[parts.length - 1];
              const isValidPropertyUniqueId =
                (property_unique_id.match(/_/g) || []).length === 2;

              if (!isValidPropertyUniqueId) {
                return (
                  <div
                    className="col"
                    style={{
                      minHeight: "50vh",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column", // Ensure vertical stacking of children
                      textAlign: "center"
                    }}
                  >
                    <img src={NoDataIMG} alt=" " />
                    <h1>
                      No such property found. Please use a valid link to upload
                      images.
                    </h1>
                  </div>
                );
              }
              return (
                <UploadPropertyImages
                  propertyUniqueId={property_unique_id}
                />
              );
            }}
          />
          <Route
            exact
            path="/admin/login"
            render={() =>
              adminToken ? <Redirect to="/admin" /> : <AdminLogin />
            }
          />
          <Route exact path="/about-us" component={AboutUs} />
          <Route
            exact
            path="/bank-auction-properties"
            component={BankAuction}
          />
          <Route exact path="/buy-sale" component={BuySale} />
          <Route
            exact
            path="/login"
            render={() =>
              userToken ? (
                savePropertyLink?.data !== null ? (
                  <Redirect to={savePropertyLink?.data} />
                ) : (
                  <Redirect to="/" />
                )
              ) : (
                <Login />
              )
            }
          />
          <Route
            exact
            path="/signup"
            render={() => (userToken ? <Redirect to="/" /> : <Signup />)}
          />
          <Route exact path="/disclaimer" component={Disclaimer} />
          <Route
            path="/auction-property-details/:id"
            render={() =>
              userToken ? (
                <AuctionPropertyDetails />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { link: location.pathname },
                  }}
                />
              )
            }
          />
          <Route
            path="/buy-sale-property-details/:id"
            render={() =>
              userToken ? (
                <BuySalePropertyDetails />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { link: location.pathname },
                  }}
                />
              )
            }
          />
          <Route exact path="/search-results" component={SearchResults} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/our-services" component={OurServices} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route
            exact
            path="/my-profile"
            render={() =>
              userToken ? <MyProfile /> : <Redirect to="/login" />
            }
          />
          <Route
            exact
            path="/edit-profile"
            render={() =>
              userToken ? <EditProfile /> : <Redirect to="/login" />
            }
          />
          <Route exact path="/knowledge-hub" component={KnowledgeHub} />
          <Route
            exact
            path="/downloads"
            render={() => (userToken ? <Downloads /> : <Login />)}
          />
          <Route exact path="/vastu-consultants" component={VastuConsultants} />
          <Route exact path="/property-lawyers" component={PropertyLawyers} />
          <Route exact path="/property-dealers" component={PropertyDealers} />
          <Route exact path="/FAQ" component={FAQ} />
          <Route exact path="/advertise-with-us" component={AdvertiseWithUs} />
          <Route
            exact
            path="/business-associates"
            component={BusinessAssociates}
          />
          <Route
            exact
            path="/business-enquiries"
            component={BusinessEnquiries}
          />
          <Route exact path="/architects" component={Architects} />
          <Route exact path="/property-valuers" component={PropertyValuers} />
          <Route component={NotFoundPage} />
        </Switch>
        <div className="container-fluid m-0 p-0">
          {!location.pathname?.includes("/admin") && <Footer />}
        </div>
      </div>
    </SearchContext.Provider>
  );
}

export default App;
