import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { URL_CONSTANTS } from "../../constants/apiConstants";

const PropertyImagelinkModal = ({ showPropertyImagelinkModal, handleCloseModal, url }) => {
 
  const link = `${process.env.REACT_APP_BASE_URL}property/uploadImages/${url}`;
  const { addToast } = useToasts();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    addToast("Link copied to clipboard!", {
      appearance: "success",
      autoDismiss: true,
    }); 
  };

  return (
    <>
      <Modal show={showPropertyImagelinkModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Property Image Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <p>Link:</p>
              <div className="d-flex">
                <p
                  className="col-12"
                  style={{
                    wordWrap: "break-word",
                    padding: "10px",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    backgroundColor: "#f8f9fa",
                    fontFamily: "inherit",
                    fontSize: "inherit",
                  }}
                >
                  {link}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            style={{ color: "blue" }}
            onClick={handleCopyLink}
          >
            Copy
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      l̥
    </>
  );
};

export default PropertyImagelinkModal;
