import React, { useEffect, useState } from "react";
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Favorite from '@material-ui/icons/Favorite';
import ShareIcon from "@material-ui/icons/Share";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Loader from '../../components/Loader/Loader.component';
import { propertyDetailsForUser } from '../../redux/user/user.selector';
import ImageCarousal from "../../components/ImageCarousal/ImageCarousal.component";
import { conversionList } from "../conversion";
import { PropertyDetailsForUserApiCall } from '../../apiCalls/User/PropertyDetailsForUser';
import { PropertyFavouriteUserApiCall } from '../../apiCalls/UserProperty/PropertyFavouriteUserApiCall';
import { savePropertyLink } from '../../redux/user/user.actions';
import { ShowInterestApiCall } from '../../apiCalls/User/ShowInterestApiCall';
import {
  independentHouseFields,
  industrialFields,
  flatFields,
  commercialFields,
  agriculturalFields,
  bankAuctionPropertyFields,
} from '../BuySaleData';
import "./AuctionPropertyDetails.styles.scss";

const AuctionPropertyDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [state, setState] = useState({});
  const propertyDetailsState = useSelector(propertyDetailsForUser);
  const [propertyDetails, setPropertyDetails] = useState({});
  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(PropertyDetailsForUserApiCall(id));
    dispatch(savePropertyLink(null, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (propertyDetailsState.data?.property_area_unit) {
      conversionList.filter((element) => {
        if (element.label.toLowerCase() === propertyDetailsState?.data?.property_area_unit) {
          setState(element);
        }
      });
    }
    if (propertyDetailsState.data?.property_id) {
      setPropertyDetails(propertyDetailsState.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyDetailsState?.data]);

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  const handleClick = () => {
    if (navigator.share) {
      navigator.share({
        title: propertyDetails?.title,
        url: window.location.href
      }).then(() => {
        
      })
      .catch(err => {
        
      });
    } else {
      navigator.clipboard.writeText(window.location.href);
      toastView("Link Copied To Clipboard", "info");
    }
  };

  const handleAction = (favourite) => {
    setPropertyDetails({
      ...propertyDetails,
      is_favourite: favourite
    });
  }

  const favouriteProperty = () => {
    setPropertyDetails({
      ...propertyDetails,
      is_favourite: propertyDetails?.is_favourite == 0 ? 1 : 0
    });
    if(propertyDetails?.is_favourite === 0){
      dispatch(ShowInterestApiCall( propertyDetails?.property_uniqueid, toastView));
    }
    dispatch(PropertyFavouriteUserApiCall(
      propertyDetails?.is_favourite == 0 ? 1 : 0,
      propertyDetails?.property_id,
      toastView,
      handleAction
    ));
  }

  const downloadDocument = () => {
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_IMAGE_BASE_URL}${propertyDetails?.e_auction_sale_notice}`;
    link.setAttribute(
      'download',
      `FileName.pdf`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const onFilterChange = (event) => {
    conversionList.filter((el) => {
      if (el.id === event.target.value) {
        setState(el);
      }
    });
  };

  function calculateArea(sqftArea) {
    if (!sqftArea) {
      return '-';
    } else {
      let divider = state.divider;
      return (sqftArea / divider).toFixed(2);
    }
  }

  return (
    <div className="details-main-outer">
      <Loader visible={propertyDetailsState.loader} />
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs-outer">
        <Link color="inherit" href="/">
          Home
        </Link>
        <Typography color="textPrimary">{propertyDetails?.title}</Typography>
      </Breadcrumbs>
      <div className="container-fluid property-details-outer pt-0 pb-4 mt-0 mb-0">
        <div className="row mb-4 background-white m-0">
          <div className="col-12 col-lg-7 p-0 m-0">
            <ImageCarousal data={propertyDetails?.images} />
          </div>
          <div className="col-12 col-lg-5 property-info__main">
            <div className="row d-flex flex-row justify-content-between">
              <div className="col-12 col-sm-5">
                <h2 className="text-capitalize">{propertyDetails?.title || '-'}</h2>
                <p className="pb-2 text-capitalize">{propertyDetails?.institution || '-'}</p>
              </div>
              <div className="col-7 property-main-info__wrapper">
                <p className="info-heading">{propertyDetails?.property_uniqueid || '-'}</p>
                <p className="info-desc">Property Id</p>
              </div>
            </div>
            <div className="row">
              <div className="col-5 property-main-info__wrapper">
                <p className="info-heading">₹ {propertyDetails?.price || '-'}</p>
                <p className="info-desc">Reserved Price</p>
              </div>
              <div className="col-7 property-main-info__wrapper">
                <p className="info-heading property-area-wrapper">{calculateArea(propertyDetails?.property_area_sqft)}
                  <Select
                    value={state?.id ?? '1'}
                    onChange={onFilterChange}
                    className="ml-1"
                    label="Unit"
                    inputProps={{
                      name: "unit",
                      id: "unit-dropdown",
                    }}
                  >
                    {
                      conversionList.map((element) => (
                        <MenuItem value={element.id}>{element.label}</MenuItem>
                      ))
                    }
                  </Select>
                </p>
                <p className="info-desc">Area</p>
              </div>
              <div className="col-5 property-main-info__wrapper">
                <p className="info-heading">
                  {propertyDetails?.locality_name || '-'}, {' '}
                  {propertyDetails?.city_name || '-'}
                </p>
                <p className="info-desc">Location</p>
              </div>
              <div className="col-7 property-main-info__wrapper">
                <p className="info-heading">{propertyDetails?.property_type || '-'}</p>
                <p className="info-desc">Type</p>
              </div>
              <div className="col-5 property-main-info__wrapper">
                {
                  propertyDetails?.auction_start_date ? (
                    <p className="info-heading">{propertyDetails?.auction_start_date || '-'} / {propertyDetails?.auction_start_time || '-'}</p>
                  ) : (
                    <p className="info-heading coming-soon-label">Coming soon!!</p>
                  )
                }
                <p className="info-desc">Auction Date/Time</p>
              </div>
              <div className="col-7 property-main-info__wrapper">
                {propertyDetails?.contact_agent?.replace(/ /g, "")?.split(',')?.map(dat => (
                  <a className="info-heading secondary-dark" href={`tel:${dat}`}>{dat},{'  '}</a>
                ))}
                <p className="info-desc">Contact Details</p>
              </div>
            </div>
            <div className="row pt-3">
              <div className="icon-wrapper d-flex flex-row">
                <div className="d-flex flex-column align-items-center">
                  <Tooltip title={propertyDetails?.is_favourite == 0 ? 'Add to favourite' : 'Remove from favourite'}>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={favouriteProperty}
                    >
                      {propertyDetails?.is_favourite == 0 ? (
                        <FavoriteBorderIcon />
                      ) : (
                        <Favorite style={{ fill: 'red' }} />
                      )}
                    </IconButton>
                  </Tooltip>
                  <span className="small-text">Interest</span>
                </div>
                <div className="d-flex flex-column align-items-center ml-3">
                  <Tooltip title="Share">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={handleClick}
                    >
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                  <span className="small-text">Share</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid property-sub-info__main px-5 pt-5 pb-0">
          <h3 className="details-heading">Auction Details</h3>
          <div className="row">
            <div className="col-12 property-sub-info__wrapper">
              <p className="info-heading">Property Address</p>
              <p className="info-desc">{propertyDetails?.lot_details || '-'}</p>
            </div>
            {
              bankAuctionPropertyFields.map((property) => (
                <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                  <p className="info-heading">{property.label}</p>
                  <p className="info-desc"> {property?.isCurrency && '₹ '} {propertyDetails[property.value] || '-'}</p>
                </div>
              ))
            }
            <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
              <p className="info-heading">Download Sale Notice</p>
              {
                propertyDetails?.e_auction_sale_notice ? (
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    className="mr-3"
                    onClick={downloadDocument}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                ) : (
                  <p className="info-desc">No documents available</p>
                )
              }

            </div>
          </div>
        </div>
        <div className="container-fluid property-sub-info__main px-5 pt-5 pb-0 mt-3">
          <h3 className="details-heading">Property Details</h3>
          <div className="row">
            {
              propertyDetails?.prop_type_indication === 1 ? (
                <>
                  {
                    flatFields.map((property) => (
                      <>
                        {
                          property?.isMainSection ? (
                            <>
                              {
                                property?.value !== 'address' ? (
                                  <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                                    <p className="info-heading">{property.label}</p>
                                    {
                                      property?.isPhoneNumber ? (
                                        <a className="info-desc secondary-dark" href={`tel:${propertyDetails[property.value]}`}>{propertyDetails[property.value]}</a>
                                      ) : (
                                        <p className="info-desc">{property?.isBoolean ? (propertyDetails[property.value] === 1 ? 'Yes' : 'No') : (propertyDetails[property.value] || '-')}</p>
                                      )
                                    }
                                  </div>
                                ) : (<></>)
                              }
                            </>
                          ) : (
                            <>
                            </>
                          )
                        }
                      </>
                    ))
                  }
                </>
              ) : (
                propertyDetails?.prop_type_indication === 2 ? (
                  <>
                    {
                      independentHouseFields.map((property) => (
                        <>
                          {
                            property?.isMainSection ? (
                              <>
                                {
                                  property?.value !== 'address' ? (
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                                      <p className="info-heading">{property.label}</p>
                                      {
                                        property?.isPhoneNumber ? (
                                          <a className="info-desc secondary-dark" href={`tel:${propertyDetails[property.value]}`}>{propertyDetails[property.value]}</a>
                                        ) : (
                                          <p className="info-desc">{property?.isBoolean ? (propertyDetails[property.value] === 1 ? 'Yes' : 'No') : (propertyDetails[property.value] || '-')}</p>
                                        )
                                      }
                                    </div>
                                  ) : (<></>)
                                }
                              </>
                            ) : (
                              <>
                              </>
                            )
                          }
                        </>
                      ))
                    }
                  </>
                ) : (
                  propertyDetails?.prop_type_indication === 3 ? (
                    <>
                      {
                        industrialFields.map((property) => (
                          <>
                            {
                              property?.isMainSection ? (
                                <>
                                  {
                                    property?.value !== 'address' ? (
                                      <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                                        <p className="info-heading">{property.label}</p>
                                        {
                                          property?.isPhoneNumber ? (
                                            <a className="info-desc secondary-dark" href={`tel:${propertyDetails[property.value]}`}>{propertyDetails[property.value]}</a>
                                          ) : (
                                            <p className="info-desc">{property?.isBoolean ? (propertyDetails[property.value] === 1 ? 'Yes' : 'No') : (propertyDetails[property.value] || '-')}</p>
                                          )
                                        }
                                      </div>
                                    ) : (<></>)
                                  }
                                </>
                              ) : (
                                <> </>
                              )
                            }
                          </>
                        ))
                      }
                    </>
                  ) : (
                    propertyDetails?.prop_type_indication === 4 ? (
                      <>
                        {
                          commercialFields.map((property) => (
                            <>
                              {
                                property?.isMainSection ? (
                                  <>
                                    {
                                      property?.value !== 'address' ? (
                                        <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                                          <p className="info-heading">{property.label}</p>
                                          {
                                            property?.isPhoneNumber ? (
                                              <a className="info-desc secondary-dark" href={`tel:${propertyDetails[property.value]}`}>{propertyDetails[property.value]}</a>
                                            ) : (
                                              <p className="info-desc">{property?.isBoolean ? (propertyDetails[property.value] === 1 ? 'Yes' : 'No') : (propertyDetails[property.value] || '-')}</p>
                                            )
                                          }
                                        </div>
                                      ) : (<></>)
                                    }
                                  </>
                                ) : (
                                  <></>
                                )
                              }
                            </>
                          ))
                        }
                      </>
                    ) : (
                      <>
                        {
                          agriculturalFields.map((property) => (
                            <>
                              {
                                property?.isMainSection ? (
                                  <>
                                    {
                                      property?.value !== 'address' ? (
                                        <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                                          <p className="info-heading">{property.label}</p>
                                          {
                                            property?.isPhoneNumber ? (
                                              <a className="info-desc secondary-dark" href={`tel:${propertyDetails[property.value]}`}>{propertyDetails[property.value]}</a>
                                            ) : (
                                              <p className="info-desc">{property?.isBoolean ? (propertyDetails[property.value] === 1 ? 'Yes' : 'No') : (propertyDetails[property.value] || '-')}</p>
                                            )
                                          }
                                        </div>
                                      ) : (<></>)
                                    }
                                  </>
                                ) : (
                                  <></>
                                )
                              }
                            </>
                          ))
                        }

                      </>
                    )
                  )
                )
              )
            }
          </div>
        </div>

        <div className="container-fluid property-sub-info__main px-5 pt-5 pb-0 mt-3">
          <h3 className="details-heading">Other Amenities</h3>
          <div className="row">
            {
              propertyDetails?.prop_type_indication === 1 ? (
                <>
                  {
                    flatFields.map((property) => (
                      <>
                        {
                          !property?.isMainSection ? (
                            <>
                              <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                                <p className="info-heading">{property.label}</p>

                                {
                                  property?.isPhoneNumber ? (
                                    <a className="info-desc secondary-dark" href={`tel:${propertyDetails[property.value]}`}>{propertyDetails[property.value]}</a>
                                  ) : (
                                    <p className="info-desc">{property?.isBoolean ? (propertyDetails[property.value] === 1 ? 'Yes' : 'No') : (propertyDetails[property.value] || '-')}</p>
                                  )
                                }
                              </div>
                            </>
                          ) : (
                            <>
                            </>
                          )
                        }
                      </>
                    ))
                  }
                </>
              ) : (
                propertyDetails?.prop_type_indication === 2 ? (
                  <>
                    {
                      independentHouseFields.map((property) => (
                        <>
                          {
                            !property?.isMainSection ? (
                              <>
                                <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                                  <p className="info-heading">{property.label}</p>
                                  {
                                    property?.isPhoneNumber ? (
                                      <a className="info-desc secondary-dark" href={`tel:${propertyDetails[property.value]}`}>{propertyDetails[property.value]}</a>
                                    ) : (
                                      <p className="info-desc">{property?.isBoolean ? (propertyDetails[property.value] === 1 ? 'Yes' : 'No') : (propertyDetails[property.value] || '-')}</p>
                                    )
                                  }
                                </div>
                              </>
                            ) : (
                              <>
                              </>
                            )
                          }
                        </>
                      ))
                    }
                  </>
                ) : (
                  propertyDetails?.prop_type_indication === 3 ? (
                    <>
                      {
                        industrialFields.map((property) => (
                          <>
                            {
                              !property?.isMainSection ? (
                                <>
                                  <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                                    <p className="info-heading">{property.label}</p>
                                    {
                                      property?.isPhoneNumber ? (
                                        <a className="info-desc secondary-dark" href={`tel:${propertyDetails[property.value]}`}>{propertyDetails[property.value]}</a>
                                      ) : (
                                        <p className="info-desc">{property?.isBoolean ? (propertyDetails[property.value] === 1 ? 'Yes' : 'No') : (propertyDetails[property.value] || '-')}</p>
                                      )
                                    }
                                  </div>
                                </>
                              ) : (
                                <> </>
                              )
                            }
                          </>
                        ))
                      }
                    </>
                  ) : (
                    propertyDetails?.prop_type_indication === 4 ? (
                      <>
                        {
                          commercialFields.map((property) => (
                            <>
                              {
                                !property?.isMainSection ? (
                                  <>
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                                      <p className="info-heading">{property.label}</p>
                                      {
                                        property?.isPhoneNumber ? (
                                          <a className="info-desc secondary-dark" href={`tel:${propertyDetails[property.value]}`}>{propertyDetails[property.value]}</a>
                                        ) : (
                                          <p className="info-desc">{property?.isBoolean ? (propertyDetails[property.value] === 1 ? 'Yes' : 'No') : (propertyDetails[property.value] || '-')}</p>
                                        )
                                      }
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )
                              }
                            </>
                          ))
                        }
                      </>
                    ) : (
                      <>
                        {
                          agriculturalFields.map((property) => (
                            <>
                              {
                                !property?.isMainSection ? (
                                  <>
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-2 property-sub-info__wrapper">
                                      <p className="info-heading">{property.label}</p>
                                      {
                                        property?.isPhoneNumber ? (
                                          <a className="info-desc secondary-dark" href={`tel:${propertyDetails[property.value]}`}>{propertyDetails[property.value]}</a>
                                        ) : (
                                          <p className="info-desc">{property?.isBoolean ? (propertyDetails[property.value] === 1 ? 'Yes' : 'No') : (propertyDetails[property.value] || '-')}</p>
                                        )
                                      }
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )
                              }
                            </>
                          ))
                        }
                      </>
                    )
                  )
                )
              )
            }
          </div>
        </div>

      </div>
    </div>
  );
};

export default AuctionPropertyDetails;
